<!-- 申请注销 -->
<template>
  <div class="do-page-writeoff">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="申请注销">
    </base-nav-bar>
    <van-dialog
      v-model:show="showDialog"
      title="提示"
      class="do-com-dialog dialog-writeoff"
      show-cancel-button
      @confirm="confirmOff"
    >
      <div class="tip">你确定要注销吗？</div>
    </van-dialog>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-writeoff-content">
        <div class="title">客流易平台用户注销规则</div>
        <div class="info">
          <div :style="`margin-bottom: 0.4rem`">
            &nbsp; &nbsp;&nbsp;客流易 APP
            提醒您，账户一旦注销，您在平台所有的权益及保存收藏的报告等都一并失效。如果您确认要注销账户，请阅读并确认以下信息：
          </div>
          <div>1. 您的账号处于安全状态</div>
          <div :style="`margin-bottom:  0.4rem`">
            账号正常使用中且无违规，被盗等情形。
          </div>
          <div>2. 注销后您的账号内的信息将会清除</div>
          <div>&nbsp; &nbsp; 包括但不限于</div>
          <div>
            &nbsp; &nbsp; <span class="circle">•</span> 个人资料、相关信息；
          </div>
          <div>
            &nbsp; &nbsp; <span class="circle">•</span> 绑定的第三方账号；
          </div>
          <div>
            &nbsp; &nbsp; <span class="circle">•</span> 保存收藏的数据报告；
          </div>
          <div :style="`margin-bottom:  0.4rem`">
            &nbsp; &nbsp; 以及您在客流易APP中留存的其他信息。
          </div>
          <div>温馨提示</div>
          完成注销后，重新再登录时，则为新账号。
        </div>
        <div class="submit-btn-wrap">
          <div @click="showDialog = true" class="submit-btn">同意并注销</div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { sendWriteOff } from "@/axios/user";
import { useStore } from "vuex";
import { Toast } from "vant";
const store = useStore();
const router = useRouter();
const showDialog = ref(false);
const loading = ref(false);

async function confirmOff() {
  if (loading.value) return;
  console.log("confirmOff");
  try {
    // 登录类型，写死 1
    loading.value = true;
    await sendWriteOff();
    loading.value = false;
    await store.dispatch("logout");
    router.replace("/login");
  } catch (error) {
    if (error) {
      Toast({
        message: error.msg || "注销失败，未知错误",
      });
    }
  }
  setTimeout(() => {
    loading.value = false;
  }, 200);
}
console.log("router", router);
</script>

<style lang="scss">
.dialog-writeoff {
  .van-dialog__content {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
}
.do-page-writeoff {
  background: #ffffff;

  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: #ffffff;
  }
  &-content {
    padding: 0 0.5rem !important;
    .submit-btn-wrap {
      display: flex;
      justify-content: center;
    }
    .submit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.96rem;
      height: 0.84rem;
      background: #0057ff;
      border-radius: 0.16rem;
      font-size: 0.3rem;
      font-weight: 500;
      color: #ffffff;
      margin-top: 1.2rem;
    }
    .circle {
      color: #000000;
      font-size: 0.3rem;
      font-weight: bold;
    }
    .title {
      font-size: 0.3rem;
      font-weight: 500;
      color: rgba(2, 4, 15, 0.85);
      margin-bottom: 0.42rem;
      padding-top: 0.42rem;
    }
    .info {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.65);
    }
  }
}
</style>
